<template>
  <div class="container">
    <div class="header">
      <div class="flagship-list">
        <van-button :class="{'active':active == 1}" type="primary" @click="switchTab(1)">金星旗舰店</van-button>
        <van-button :class="{'active':active == 2}" type="primary" @click="switchTab(2)">银星旗舰店</van-button>
      </div>
    </div>
    <div class="rank-content">
      <van-row class="van-head">
        <van-col span="3"></van-col>
        <van-col span="6" class="user-name">姓名</van-col>
        <van-col span="7">订货业绩</van-col>
        <van-col span="4">PK名次</van-col>
        <van-col span="4">排名情况</van-col>
      </van-row>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-row v-for="item in list" :key="item.pkRanking">
          <van-col span="3" class="user-head">
            <img :src="item.headerImg ? item.headerImg :'/img/100.b3582921.png'" />
          </van-col>
          <van-col span="6" class="user-info">
            <div class="ellipsis1"><template v-if="item.isMe">(我的)</template>{{ item.agentName }}</div>
            <!-- <p class="ellipsis1">{{ item.agentCode }}</p> -->
          </van-col>
          <van-col span="7" class="user-perf">￥{{ item.performance }}</van-col>
          <van-col span="4" class="rank-index">{{ item.pkRanking }}</van-col>
          <van-col class="ivv-reward ellipsis2" :class="{'horizontal':item.uporDown === 0}" span="4">
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210323164100130.png" v-if="item.uporDown === 1" />
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210323164118801.png" v-else-if="item.uporDown === 2" />
            <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210324114646176.png" v-else />
          </van-col>
        </van-row>
      </van-list>
    </div>
  </div>
</template>

<script>
import { replaceAppToken, deliveryMessage } from "../../../utils/appUtil";
let isFirstCome = true;
export default {
  name: "IndexList",
  data() {
    return {
      active: 1,
      pg: 0,
      ps: 20,
      loading: true,
      finished: false,
      list: [],
      token: "",
      source: ""
    }
  },
  created() {
    let { token, source } = this.$route.query;
    if (token) {
      this.token = token;
      replaceAppToken(token, () => {
        this.onLoad();
        this.getHomeIdenty()
      })
    } else {
      this.onLoad();
      this.getHomeIdenty();
    }
    if (source) {
      this.source = source;
    }
  },
  methods: {
    switchTab(type) {
      this.active = type;
      this.pg = 0;
      this.list = [];
      this.loading = true;
      this.finished = false;
      this.onLoad();
    },
    //获取身份信息
    getHomeIdenty() {
      this.get("/TeamAgent/AgentInfo/HomeIdentity_V3").then(res => {
        if (res.code === 1) {
          let list = res.response;
          let maxLevel = 0;
          list.forEach(it => {
            if (it.homelevel > maxLevel) maxLevel = it.homelevel;
          });
          if (maxLevel <= 2) {
            return this.$dialog.confirm({ title: "提示", message: "蜜运会PK赛为旗舰店专享噢！", confirmButtonText: "开旗舰店" }).then(() => {
              window.location.href = this.baseHost + "/7999/Redesign/html/applyStore/pinkDiamond.html";
            }).catch(() => {
              if (this.token) {
                deliveryMessage({ 'action': 'goHome' })
              } else {
                window.location.href = this.baseHost + "/#/"
              }
            });
          } else if (maxLevel < 5) {
            this.$router.push({ path: '/FlagshipIndex/' + (maxLevel === 4 ? 1 : 2) + '?token=' + this.token + "&source=" + this.source })
          }
        }
      })
    },
    onLoad() {
      this.pg++;
      this.get("/OnlyOnce/HeroCompetition/GetPkRankList", {
        levelid: this.active + 1,
        pageIndex: this.pg,
        pageSize: this.ps
      }, 2).then(res => {
        this.loading = false;
        if (!res.total && this.source != 'result') {
          this.$router.push("/PKResult?token=" + token);
        }
        if (res.code === 1) {
          let { msgInfo, list } = res.response;
          this.list = list;
          this.finished = true;
          if (msgInfo && isFirstCome) {
            isFirstCome = false;
            this.$dialog.confirm({ title: "提示", message: msgInfo, confirmButtonText: "采购订货" }).then(() => {
              if (this.token) {
                return deliveryMessage({ 'action': 'jumpShopkeeperOrder' })
              }
              window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html";
            })
          }
        } else {
          this.finished = true;
          this.$dialog.confirm({ title: "提示", message: res.msg, confirmButtonText: "采购订货" }).then(() => {
            if (this.token) {
              return deliveryMessage({ 'action': 'jumpShopkeeperOrder' })
            }
            window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html";
          })
        }
      }).catch((res) => {

        this.loading = false;
        this.finished = true;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 150px 25px 11px;
  background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210913110855394.png")
    no-repeat #c5e1ea;
  background-size: 100% auto;

  .header {
    padding-bottom: 11px;
    text-align: center;

    .flagship-list {
      .van-button {
        height: 32px;
        border-color: #fff;
        border-radius: 32px;
        margin-right: 10%;
        background: linear-gradient(to bottom, #f4f4f4, #afafaf);
        background: -webkit-linear-gradient(to bottom, #f4f4f4, #afafaf);
        background: -moz-linear-gradient(to bottom, #f4f4f4, #afafaf);
        box-shadow: 0 3px 5px #c3c3c3;
        &.active {
          background: linear-gradient(to bottom, #d5f5ff, #44a8d1);
          background: -webkit-linear-gradient(to bottom, #d5f5ff, #44a8d1);
          background: -moz-linear-gradient(to bottom, #d5f5ff, #44a8d1);
          box-shadow: 0 3px 5px #75b0c9;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .rank-content {
    position: absolute;
    top: 200px;
    left: 25px;
    right: 25px;
    bottom: 11px;
    overflow-y: auto;
    text-align: center;
    padding: 11px 15px;
    background-color: #fff;

    .van-head {
      padding: 11px 0 11px;

      .user-name {
        text-align: left;
      }
    }

    .van-list {
      .van-row {
        display: flex;
        height: 45px;
        display: -webkit-flex;
        align-items: center;
        position: relative;

        .user-head {
          text-align: left;

          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
          }
        }

        .user-info {
          color: #000000;
          text-align: left;

          p {
            color: #999999;
            font-size: 9px;
            padding-top: 3px;
          }
        }

        .user-perf {
          color: #fd0000;
          font-size: 14px;
        }

        .rank-index {
          color: #333333;
          font-size: 16px;
        }

        .ivv-reward {
          color: #f9b022;
          font-size: 0.364rem;

          img {
            width: 10px;
          }

          &.horizontal {
            img {
              width: 17px;
            }
          }
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #f5f5f5;
        }
      }
    }
  }
}
</style>
